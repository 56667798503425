import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import amplitude from "amplitude-js";

import {
  action,
  thunk,
  createStore,
  StoreProvider,
  computed,
} from "easy-peasy";

import App from "./App";

// This line is to never be checked into git other than a FALSE value – to prevent prod fiascos.
var IS_DEBUG = false;
if (process && process.env.NODE_ENV == "production") {
  console.log(
    `Forcing IS_DEBUG in react app to false. process.env.NODE_ENV is availble and is ${process.env.NODE_ENV}`
  );
  IS_DEBUG = false;
}

const mapStepToName = (stepId) => {
  switch (stepId) {
    case 0:
      return "PINNING";
      break;
    case 1:
      return "MIC";
      break;
    case 2:
      return "NOTE";
      break;
    case 3:
      return "AUTH";
      break;
    default:
      return "UNDEFINED";
      break;
  }
};

const storeModel = {
  isLoading: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  configLoaded: false,
  setConfigLoaded: action((state, payload) => {
    state.configLoaded = true;
  }),
  extId: null,
  setExtId: action((state, payload) => {
    state.extId = payload;
  }),
  apiToken: "3dd5d9c3-40fc-4dd4-85ce-36e5c2ac27f2",
  googleClientId: null,
  setGoogleClientId: action((state, payload) => {
    state.googleClientId = payload;
  }),
  uid: null,
  isLoggedIn: computed((state) => (state.uid ? true : false)),
  /**
   * first_name, last_name, email, profile_pic
   */
  user: {
    first_name: "Loading ...",
    last_name: "",
    email: "",
    profile_pic: null,
  },
  AnonUser: {
    first_name: "Anonymous",
    last_name: "Profile",
    email: "No Email provided",
    profile_pic: null,
  },
  baseUrl: null,
  setBaseUrl: action((state, payload) => {
    state.baseUrl = payload;
  }),
  setUid: action((state, payload) => {
    // We're either setting a UID coming from LS, or a uid from the payload (coming from the extension's LS.)
    let uid = payload || localStorage.getItem("user_hash");
    if (uid && uid != "null") {
      state.uid = uid;
      localStorage.setItem("user_hash", uid);
      state.amplitude.getInstance().setUserId(uid);
    }
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  isDebug: IS_DEBUG,
  setIsDebug: action((state, payload) => {
    state.isDebug = payload;
  }),
  isChrome: false,
  setIsChrome: action((state, payload) => {
    state.isChrome = true;
  }),
  isExtensionInstalled: false,
  setIsExtensionInstalled: action((state, payload) => {
    state.isExtensionInstalled = true;
  }),
  amplitude: computed((state) => {
    amplitude.getInstance().init("ca06d6ff5aac249520933467c8f648bf");
    return amplitude;
  }),
  logger: thunk(async (actions, payload, helper) => {
    // trigger GA/Amplitude logging.
    let { event, properties } = payload;

    let propCopy = properties;
    if (
      properties &&
      properties.step !== undefined &&
      typeof properties.step === "number"
    ) {
      propCopy = {
        ...properties,
        ...{ step: mapStepToName(properties.step) },
      };
    }
    if (helper.getState().isDebug) {
      console.log(event, propCopy);
    } else {
      helper.getState().amplitude.logEvent(event, propCopy);
    }
  }),
  isFooterShown: true,
  hideFooter: action((state) => {
    state.isFooterShown = false;
  }),
  currentOnboardingPage: 0,
  setCurrentOnboardingPage: action((state, payload) => {
    state.currentOnboardingPage = payload;
  }),
  isPinned: false,
  setIsPinned: action((state, payload) => {
    state.isPinned = true;
  }),
  isMicGranted: false,
  setIsMicGranted: action((state, payload) => {
    state.isMicGranted = true;
  }),
  isNoteMade: false,
  setIsNoteMade: action((state, payload) => {
    state.isNoteMade = true;
  }),
  isAuthed: false,
  setIsAuthed: action((state, payload) => {
    state.isAuthed = true;
  }),
  // loadConfig sets the initial state: isLoggedIn (uid present), isChrome, and isExtensionInstalled.
  loadConfig: thunk(async (actions, payload, helper) => {
    console.log(
      "🚀 Loading extension config and setting up initial landingState ..."
    );

    // What does loadConfig need to do? well, a few things.
    // First, load the config from localstorage; this includes the user token, uid, and whatever other info we have on the user.
    // Ideally, just the uid and we get the rest from remote.
    // Second, it needs to ping the extension to check whether mic is granted, and what debug state we're in, and get the uid to "only"
    // save it locally if one doesn't exist. The two uids should mirror each other at all times. If that doesn't happen then
    // something went horribly wrong.
    // Lastly, determine whether the user is on chrome and if the extension is installed.

    // From LS, as there's no payload.
    actions.setUid();

    // The logic below only applies to the ext; it'll throw an error if not on chrome and that won't break anything.
    try {
      if (chrome && navigator.userAgent.match(/Chrom/)) actions.setIsChrome();
      if (!chrome.runtime) throw new TypeError("No extensions installed.");

      chrome.runtime.sendMessage(
        helper.getState().extId,
        { text: "loadConfig" },
        // config: {isDebug, uid, micPermissionStatus}
        (resp) => {
          const config = resp.config;
          // We know for a fact now that the extension is installed, because it answered the call.
          actions.setIsExtensionInstalled();

          // This is to make sure we can force the backend to act in debug mode,
          // by relying on the extension's debug state to toggle the react app's debug mode.
          //
          // If the react app is in prod mode (IS_DEBUG == false): check if the extension calling is in debug mode.
          // Else, rely on react app's debug mode to decide whether we're in debug or not (IS_DEBUG, at the top of this file).
          if (helper.getState().isDebug == false && config !== undefined) {
            actions.setIsDebug(config.isDebug);
          }
          if (config.uid) {
            /**
             * There are three cases here:
             *  1. Ext UID is the same as local UID and both exist.
             *  2. Ext UID is different from local UID (regardless of whether they exist).
             *  3. Ext UID or local UID don't exist.
             * Scenario 1 is all raindbows and unicorns.
             * Scenario 2 is an issue and one UID needs to override the other.
             *   This happens when a user had just installed TAC (uid exists in ext/fetched from the backend) but haven't gone through onboarding;
             *   and when a user had gone through onboarding without installing the extension (shouldn't happen, but still),
             *   also when a user removes the extension. We'll update the ext with the local uid.
             * Scenario 3 is easy as the one UID that exists will be the adopted one. It's a variation of scenario 2.
             */
            if (config.uid == localStorage.getItem("user_hash")) {
              // Scenario 1. Do nothing. Rainbows and unicorns.
            } else if (config.uid != localStorage.getItem("user_hash")) {
              // Scenario 2 and 3. LS uid should be source of truth, and use ext uid if LS uid doesn't exist.
              if (localStorage.getItem("user_hash")) {
                actions.updateConfig({
                  user_hash: localStorage.getItem("user_hash"),
                });
              } else if (config.uid) {
                actions.setUid(config.uid);
              }
            }
          }
          if (config.micPermissionStatus == "granted")
            actions.setIsMicGranted(true);
          actions.setConfigLoaded();
          console.log(`🚀 config loaded and set to ${JSON.stringify(config)}`);
        }
      );
    } catch (error) {
      if (error instanceof ReferenceError) {
        // ReferenceError: 'chrome' not defined, which means not on Chrome.
        // Do nothing. Already initiating isChrome to false.
      } else if (
        error.message.match(
          /Invalid extension id|Cannot read property|No extensions installed/
        )
      ) {
        // Is on Chrome, but TAC doesn't exist.
        // Also do nothing. Already initited isExtensionInstalled to false.
      } else if (error instanceof TypeError) {
        // Ideally it doesn't come to this ... but you never know.
        actions.logger({
          event: "ERROR_LOADCONFIG",
          properties: { message: error.message },
        });
      }
      actions.setConfigLoaded();
    } finally {
    }
  }),
  updateConfig: thunk(async (actions, payload, helper) => {
    console.log("🚀 Updating extension config ...");
    const user_hash = payload.user_hash;
    try {
      chrome.runtime.sendMessage(
        helper.getState().extId,
        { text: "updateConfig", config: { user_hash: user_hash } },
        ({ type, success }) => {
          console.log(success);
        }
      );
    } catch (error) {
      console.log("Can't updateConfig");
      if (
        error instanceof TypeError &&
        error.message.match(/Invalid extension id/)
      ) {
        alert(
          "Talk&Comment isn't installed, you'll be redirected to install it before you proceed."
        );
      }
    }
  }),
  signout: thunk(async (actions, payload, helper) => {
    try {
      chrome.runtime.sendMessage(
        helper.getState().extId,
        { text: "signout" },
        ({ type, success }) => {
          actions.logger({
            event: "SIGNOUT",
          });
          actions.setUid(null);
          localStorage.removeItem("user_hash");
          localStorage.removeItem("token");
          helper.getState().amplitude.getInstance().setUserId(undefined);
          console.log("Signed out");
          document.location = "/";
        }
      );
    } catch (error) {
      alert("There has been an error logging you out.");
    }
  }),
  tracksPage: 1,
  tracksTotal: null,
  tracksList: [],
  setTracks: action((state, payload) => {
    state.tracksPage = payload.page;
    state.tracksTotal = payload.total;
    state.tracksList.push(...payload.list);
  }),
  /**
   * id: user id in db
   * user_hash: equal to uid
   * email
   * created_at: creation date
   * first_name
   * last_name
   * tracks: object
   *    total: sum of all tracks
   *    page: current page index
   *    list: array of tracks (as defined by the limit N=10, offset=0) ordered by created_at desc
   *      id: track id / 4e260ac153466aff1543605d
   *      created_at
   *      player_url: /p/id
   *      file_url: cdn url to the file (originally uploaded, not transcoded)
   *      source: where it was recorded from
   */
  fetchUserData: thunk(async (actions, payload, helper) => {
    console.log("fetchuserdata...");
    actions.setIsLoading(true);
    let page = payload || 1;
    let url = `${helper.getState().baseUrl}/api/v3/users/${
      helper.getState().uid
    }?page=${page}&api_token=${helper.getState().apiToken}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        actions.setTracks(data.tracks);
        // Check if the user's profile has been filled out or if it's an anonymous user.
        actions.setUser({
          first_name: data.first_name || helper.getState().AnonUser.first_name,
          last_name: data.last_name || helper.getState().AnonUser.last_name,
          email: data.email || helper.getState().AnonUser.email,
          profile_pic:
            data.profile_pic || helper.getState().AnonUser.profile_pic,
        });
        actions.setIsLoading(false);
      })
      .catch((error) => {
        actions.logger({
          event: "FETCH_TRACKS_ERROR",
          properties: { message: error.message },
        });
        actions.setIsLoading(false);
      });
  }),
};

const store = createStore(storeModel);

/**
 * This modules handles all the high level abstractions of the app.
 * Some the jobs to be done by this module:
 *   - Initial app routing (for people with the app installed go to onboarding flow,
 *   otherwise ask them to install it.)
 *   - Pass server-side config to React app as props (ex. EXT_ID)
 *   - Manage and trickle down the redux store.
 */
function index(props) {
  const setExtId = store.getActions().setExtId;
  const logger = store.getActions().logger;
  const loadConfig = store.getActions().loadConfig;
  const setBaseUrl = store.getActions().setBaseUrl;
  const setGoogleClientId = store.getActions().setGoogleClientId;

  setExtId(props.EXT_ID);
  setBaseUrl(props.BASE_URL);
  setGoogleClientId(props.GOOGLE_CLIENT_ID);

  // This kicks off the entire app's logic.
  if (!store.getState().configLoaded) loadConfig();

  return (
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  );
}

index.propTypes = {
  EXT_ID: PropTypes.string,
};

export default index;
